import React from 'react';
import { ReferenceField, TextField, ReferenceInput, AutocompleteInput, FunctionField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const solutions = [
    { name: "A", cname: "a", value: "a" },
    { name: "B", cname: "b", value: "b" },
    { name: "C", cname: "c", value: "c" },
    { name: "D", cname: "d", value: "d" },
    { name: "E", cname: "e", value: "e" },
    { name: "F", cname: "f", value: "f" },
];

export const QuestionsList = props => (
    <div>
        <ListGuesser {...props}>
        <FieldGuesser source={"name"} />
        <ReferenceField label="IqTest" source="iqTest" reference="iq_tests">
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField label="Problem Image" source="problem_image" reference="media_objects">
            <FunctionField
                render={(record) => {
                    return (
                    <img
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/${record.contentUrl}`}
                        height="100px"
                    />
                    );
                }}
                />
        </ReferenceField>
        <ReferenceField label="Option A" source="option_a" reference="media_objects">
            <FunctionField
                render={(record) => {
                    return (
                    <img
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/${record.contentUrl}`}
                        height="100px"
                    />
                    );
                }}
                />
        </ReferenceField>
        <ReferenceField label="Option B" source="option_b" reference="media_objects">
            <FunctionField
                render={(record) => {
                    return (
                    <img
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/${record.contentUrl}`}
                        height="100px"
                    />
                    );
                }}
                />
        </ReferenceField>
        <ReferenceField label="Option C" source="option_c" reference="media_objects">
            <FunctionField
                render={(record) => {
                    return (
                    <img
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/${record.contentUrl}`}
                        height="100px"
                    />
                    );
                }}
                />
        </ReferenceField>
        <ReferenceField label="Option D" source="option_d" reference="media_objects">
            <FunctionField
                render={(record) => {
                    return (
                    <img
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/${record.contentUrl}`}
                        height="100px"
                    />
                    );
                }}
                />
        </ReferenceField>
        <ReferenceField label="Option E" source="option_e" reference="media_objects">
            <FunctionField
                render={(record) => {
                    return (
                    <img
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/${record.contentUrl}`}
                        height="100px"
                    />
                    );
                }}
                />
        </ReferenceField>
        <ReferenceField label="Option F" source="option_f" reference="media_objects">
            <FunctionField
                render={(record) => {
                    return (
                    <img
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/${record.contentUrl}`}
                        height="100px"
                    />
                    );
                }}
                />
        </ReferenceField>
        <FieldGuesser source={"solution"} />
    </ListGuesser>
    </div>

);

export const QuestionCreate = props => (
    <div>
        <CreateGuesser {...props}>
            <InputGuesser source="name" />
            <ReferenceInput
                source="iqTest"
                reference="iq_tests"
                label="Iq Test"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <AutocompleteInput
                source="solution"
                choices={solutions}
                optionText="name"
                optionValue="value"
            />
            <ReferenceInput
                source="problem_image"
                reference="media_objects"
                label="Prolem Image"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_a"
                reference="media_objects"
                label="Option A"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_b"
                reference="media_objects"
                label="Option B"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_c"
                reference="media_objects"
                label="Option C"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_d"
                reference="media_objects"
                label="Option D"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_e"
                reference="media_objects"
                label="Option E"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_f"
                reference="media_objects"
                label="Option F"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            
        </CreateGuesser>
    </div>

);

export const QuestionEdit = props => (
    <div>
        <EditGuesser {...props}>
            <InputGuesser source="name" />
            <ReferenceInput
                source="iqTest"
                reference="iq_tests"
                label="Iq Test"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <AutocompleteInput
                source="solution"
                choices={solutions}
                optionText="name"
                optionValue="value"
            />
            <ReferenceInput
                source="problem_image"
                reference="media_objects"
                label="Prolem Image"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_a"
                reference="media_objects"
                label="Option A"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_b"
                reference="media_objects"
                label="Option B"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_c"
                reference="media_objects"
                label="Option C"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_d"
                reference="media_objects"
                label="Option D"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_e"
                reference="media_objects"
                label="Option E"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="option_f"
                reference="media_objects"
                label="Option F"
                filterToQuery={searchText => ({ name: searchText })}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            
        </EditGuesser>
    </div>

);


