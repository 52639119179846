import React from 'react';
import {FileInput, FileField, ImageField, FunctionField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

export const MediaObjectsCreate = (props) => (
    <CreateGuesser {...props}>
        <FileInput source="file">
            <FileField source="src" title="title" content="multipart/form-data" accept="multipart/form-data"/>
        </FileInput>
    </CreateGuesser>
);

export const MediaObjectsList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="contentUrl" />
            <FunctionField
                label="Image"
                render={(record) => {
                    return (
                    <img
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}${record.contentUrl}`}
                        height="100px"
                    />
                    );
                }}
                />
        </ListGuesser>
    </div>
)