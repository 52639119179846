import React from 'react';
import { AutocompleteInput, TextInput, BooleanInput, BooleanField, ReferenceInput, ArrayField, Datagrid, TextField, ReferenceManyField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const roles = [
    { name: "ROLE_MANAGER", cname: "manager", value: ["ROLE_MANAGER"] },
    { name: "ROLE_WORKER", cname: 'trabajador', value: ["ROLE_WORKER"] },
];

const status = [
    {name: "Enabled", cname: "enabled", value: "enabled"},
    {name: "Disabled", cname: "disabled", value: "disabled"}
]

export const IqTestsList = props => (
    <div>
        <ListGuesser {...props}>
        <FieldGuesser source={"name"} />
        <FieldGuesser source={"status"} />
        <ReferenceManyField reference="questions" target="iqTest.id" label="questions">
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="solution" />
                </Datagrid>
            </ReferenceManyField>
    </ListGuesser>
    </div>

);

export const IqTestsCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <AutocompleteInput
            source="status"
            choices={status}
            optionText="name"
            optionValue="value"
        />
    </CreateGuesser>
);

export const IqTestsEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <AutocompleteInput
            source="status"
            choices={status}
            optionText="name"
            optionValue="value"
        />
    </EditGuesser>
);
